<template>
  <microsoft-cloud />
</template>

<script>
import MicrosoftCloud from "../../components/solutions/volume-licensing/MicrosoftCloud.vue";
export default {
  name: "microsoft-cloud-page",
  components: { MicrosoftCloud },
};
</script>

<style></style>
